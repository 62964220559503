<template>
  <b-card-code>
    <b-row>
      <b-col sm="4">
        <h4>Gerencia Directiva</h4>
      </b-col>
      <b-col sm="3">
        <h4>Centro de Costo</h4>
      </b-col>
      <b-col sm="3">
        <h4>Cuenta</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group>
          <b-form-select
            v-model="adminSelected"
            text-field="gerencia"
            value-field="idGerencia"
            placeholder="Gerencia"
            :options="admins"
            @change="filter(adminSelected, null)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                default
              >
                Todas
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <b-form-select
            v-model="costSelected"
            text-field="label"
            value-field="codigo"
            :options="centers"
            @change="filter(adminSelected, costSelected)"
          >
            <template #first>
              <b-form-select-option :value="null">
                Todos
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <b-form-input
            v-model="searchTerm"
            type="text"
            class="d-inline-block"
            @change="clear(searchTerm)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="loading || searching || !searchTerm"
          @click="search(searchTerm)"
        >
          <span v-if="!searching">Buscar</span>
          <span v-if="searching">Buscando...</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="loading || (!adminSelected && !costSelected && !searchTerm)"
          @click="download(rowsFiltered)"
        >
          Exportar a Excel
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>Mostrando: {{ rowsFiltered.length }} de {{ rows.length }}</p>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rowsFiltered"
      :rtl="direction"
      :fixed-header="true"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      max-height="500px"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status.status'">
          <b-badge :variant="statusVariant(props.row.status.status)">
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="ListIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="'/cda/accounts-accounting-edit/' + props.row.idCuenta + '/' + props.row.idCentro">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <template v-if="[4, 5, '4', '5'].includes(props.row.type.idTipo)">
                <b-dropdown-item :to="'/cda/accounts-accounting-aux?centerCode='+ props.row.idCentro +'&accountName='+ props.row.cuenta +'&accountNumber='+ props.row.idCuenta">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Gestionar Auxiliar</span>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BFormGroup, BFormInput, BRow, BCol, BPagination,
  BButton, BDropdown, BDropdownItem, BFormSelect, BFormSelectOption,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'CentersTypesVue',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormSelectOption,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      admins: [],
      adminSelected: '',
      costSelected: '',
      loading: true,
      searching: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nº Cuenta Contable',
          field: 'idCuenta',
        },
        {
          label: 'Centro Costo',
          field: 'center',
        },
        {
          label: 'Cuenta Contable',
          field: 'cuenta',
        },
        {
          label: 'Tipo',
          field: 'type.tipo',
        },
        {
          label: 'Categoría',
          field: 'category.categoria',
        },
        {
          label: '% Dist. Ingreso',
          field: 'ingresos',
        },
        {
          label: '% Dist. Gastos',
          field: 'gastos',
        },
        {
          label: 'Estado',
          field: 'status.status',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      allCenters: [],
      admin: [],
      centers: [],
      rows: [],
      rowsFiltered: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/accounts/all`)
        const statuses = this.$store.getters['catalog/allStatuses']
        const categories = this.$store.getters['catalog/allCategories']
        const types = this.$store.getters['catalog/allAccountTypes']
        const respCenters = await this.$http.get(`${environment.uri}/ccenters/all`)
        const respMng = await this.$http.get(`${environment.uri}/mngmts/all`)

        if (respMng && respMng.data) {
          this.admins = respMng.data
        }

        if (respCenters && respCenters.data) {
          this.centers = respCenters.data
          this.allCenters = respCenters.data
          console.log('centrs', this.centers)
        }

        if (resp && resp.data) {
          this.rows = resp.data.map(item => {
            const status = statuses.find(s => s.idStatus === item.status) || { categoria: 'Sin Estado' }
            const category = categories.find(s => s.idCategoria === item.idCategoria) || { categoria: 'Otras Cuentas' }
            const type = types.find(s => s.idTipo === item.idTipo) || { tipo: 'Sin Tipo' }
            const center = this.centers.find(s => s.idCentro === item.idCentro) || { idCentro: null, centro: 'Sin Centro' }
            const centerId = this.centers.find(s => s.idCentro === item.idCentro) || { idCentro: null, centro: 'Sin Centro' }

            return {
              ...item,
              category,
              status,
              type,
              gastos: item.gastos * 100,
              ingresos: item.ingresos * 100,
              center: center.idCentro + ' - ' + center.centro
            }
          }).sort((a, b) => ((a.idTipo > b.idTipo) ? 1 : -1))
          this.rowsFiltered = this.rows.slice(0, 100)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    currency(value) {
      return `$${this.$options.filters.currency(value)}`
    },
    download(rows) {
      const items = rows.map(item => ({
        idCuenta: (item.idCuenta || ''),
        idCentro: (item.idCentro || ''),
        cuenta: (item.cuenta || '').replace(/,/g, ' '),
        type: (item.type.tipo || '').replace(/,/g, ' '),
        categoria: (item.category.categoria || '').replace(/,/g, ' '),
        ingresos: (item.ingresos || 0),
        gastos: (item.gastos || 0),
        status: item.status.status || '',
        aux: (item.aux || '').replace(/,/g, ' '),
        idGerencia: (item.idGerencia || ''),
      }))
      downloadExcel(items, [
        'Nº Cuenta Contable',
        'Nº Centro Costo',
        'Cuenta Contable',
        'Tipo',
        'Categoría',
        '% Dist. Ingreso',
        '% Dist. Gastos',
        'Estado',
        'Auxilar',
        'Gerencia Directiva',
      ], 'accounts-accounting-report.csv')
    },
    filter(admin, center) {
      this.rowsFiltered = []
      this.loading = true

      if ((admin || admin === 0) && (center || center === 0)) {
        this.centers = this.allCenters.filter(item => item.idGerencia === admin)
        this.rowsFiltered = this.rows.filter(item => (item.idGerencia === admin && item.idCentro === center))
      } else if (center || center === 0) {
        this.rowsFiltered = this.rows.filter(item => item.idCentro === center)
      } else if (admin || admin === 0) {
        this.centers = this.allCenters.filter(item => item.idGerencia === admin)
        this.rowsFiltered = this.rows.filter(item => item.idGerencia === admin)
      } else {
        this.centers = this.allCenters + this.idCentro
        this.rowsFiltered = this.rows.slice(0, 100)
      }
      this.loading = false
    },
    search(searchKey) {
      this.searching = true
      if (searchKey) {
        this.rowsFiltered = this.rowsFiltered.filter(obj => Object.keys(obj).some(key => String(obj[key]).toLowerCase().includes(searchKey.toLowerCase())))
      } else {
        this.rowsFiltered = this.rows.slice(0, 100)
      }
      this.searching = false
    },
    clear(searchKey) {
      if (!searchKey) {
        this.filter(this.adminSelected, this.costSelected)
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
